import { takeLatest } from 'redux-saga/effects';

import {
  FETCH_CONFIG_REQUEST,
  UPDATE_CONFIG_REQUEST,
  FETCH_IMPORT_CONFIG_REQUEST,
  VALIDATE_CONFIG_REQUEST,
  GET_NEW_RESOLVED_CONFIG_REQUEST,
  fetchConfigSaga,
  updateConfigSaga,
  fetchImportConfigSaga,
  validateConfigSaga,
  getNewResolveConfigSage,
} from '../store/modules/config';

import {
  FETCH_LAYOUT,
  fetchLayoutSaga,
} from '../store/modules/layout';

import { GET_JWT_DATA_REQUEST, getJwtDataSaga } from '../store/modules/tenants';

function* rootSaga() {
  yield takeLatest(FETCH_CONFIG_REQUEST, fetchConfigSaga);
  yield takeLatest(UPDATE_CONFIG_REQUEST, updateConfigSaga);
  yield takeLatest(VALIDATE_CONFIG_REQUEST, validateConfigSaga);
  yield takeLatest(FETCH_IMPORT_CONFIG_REQUEST, fetchImportConfigSaga);


  yield takeLatest(GET_NEW_RESOLVED_CONFIG_REQUEST, getNewResolveConfigSage);

  yield takeLatest(FETCH_LAYOUT, fetchLayoutSaga);

  yield takeLatest(GET_JWT_DATA_REQUEST, getJwtDataSaga);
}

export default rootSaga;
