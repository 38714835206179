import { setItem } from '../utils/localStorage';

export default ({ actionsToProsess }) => ({ getState }) => next => action => {
  const result = next(action);
  if (actionsToProsess.includes(action.type)) {
    const {
      attributesRef,
      relationsGridRef,
      tablesRef,
      ...layout
    } = getState().layout;
    const tenantName = getState().tenants.active.value;
    setItem(`layout.${tenantName}`, JSON.stringify(layout));
  }
  return result;
};
