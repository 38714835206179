import { createStore, applyMiddleware, compose } from 'redux';

import createSagaMiddleware from 'redux-saga';

import rootReducer from './modules';
import { actionsForLocalStorage as actionList } from './modules/layout';

import localStorageMiddleware from '../middleware/localStorageMiddleware';

export const sagaMiddleware = createSagaMiddleware();

export default function configureStore(preloadedState) {
  const reduxDevToolsExtCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = (process.env.NODE_ENV !== 'production' && reduxDevToolsExtCompose) || compose;

  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        localStorageMiddleware({ actionsToProsess: actionList }),
      ),
    ),
  );
}
