import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getJwtDataRequest } from '../store/modules/tenants';
import { fetchConfigRequest, getJWTFail } from '../store/modules/config';
import { hideModal } from '../store/modules/modal';

import {
  getActiveTenant,
  getModalVisible,
  getModalBody,
  getModalType,
  getHandleModalCloseButton,
  getModalTitle,
  getConfigGlobalLoading,
  getModalCloseButtonText,
  getModalContinueButtonText,
  getHandleModalContinueButton,
  getModalClassName,
  getConfigData,
  getServerConfig,
} from '../selectors';

import App from '../components/App';

const mapStateToProps = state => ({
  activeTenant: getActiveTenant(state),
  isModalVisible: getModalVisible(state),
  modalType: getModalType(state),
  modalTitle: getModalTitle(state),
  modalBody: getModalBody(state),
  modalCloseButtonText: getModalCloseButtonText(state),
  modalContinueButtonText: getModalContinueButtonText(state),
  handleModalContinueButton: getHandleModalContinueButton(state),
  modalClassName: getModalClassName(state),
  handleModalCloseButton: getHandleModalCloseButton(state),
  isLoaderVisible: getConfigGlobalLoading(state),
  configData: getConfigData(state),
  serverConfig: getServerConfig(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchConfigRequest,
  hideModal,
  getJwtDataRequest,
  getJWTFail,
}, dispatch);

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default AppContainer;
