import { combineReducers } from 'redux';

import config from './config';
import tenants from './tenants';
import layout from './layout';
import modal from './modal';

const rootReducer = combineReducers({
  modal,
  config,
  tenants,
  layout,
});

export default rootReducer;
