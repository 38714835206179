import React from 'react';

import { setItem } from '../../utils/localStorage';
import env from '../../config';
import { destroyLoginSession } from '../../utils/auth/action-login';

const Logout = () => {
  const logout = (callback) => {
    destroyLoginSession();
    setItem('needRedirect', 'true');
    setTimeout(callback, 0);
  };

  const redirect = () => {
    window.location.href = env('ssoRedirectUrl');
  };

  return (
    <div className="information-block">
      <div className="information-block-content">
        <div className="information-block-title">
          You do not have permissions to manage configurations. Contact your administrator.
        </div>
        <button id="log-out-button" className="btn btn-default log-out-button" onClick={() => logout(redirect)}>Log Out
        </button>
      </div>
    </div>
  );
};

export default Logout;
