import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import ConfigEditor from './components/ConfigEditor';

import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<ConfigEditor />, document.getElementById('root'));

registerServiceWorker();
