import Axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import env from '../../config';
import { setItem, getItem, removeItem } from '../localStorage';

const authJWT = 'authJWT';
const authJWTExpire = 'authJWTExpire';

export const destroyLoginSession = () => {
  eraseCookie(authJWT);
  eraseCookie(authJWTExpire);
  localStorage.clear();
};

export const onLogin = (getJWTFail) => {
  const redirectUrl = `${env('ssoUrl')}?redirect=${encodeURIComponent(window.location.href.split('#auth')[0])}`;
  const isUrlToken = !window.location.href.includes('#auth');
  const auth = Cookies.get(authJWT);
  const authExpire = Cookies.get(authJWTExpire);

  if (isUrlToken && (!auth || !authExpire)) {
    window.location.href = redirectUrl;
  } else {
    const url = decodeURIComponent(window.location.href);

    if (url.indexOf('#auth') !== -1) {
      const returnedFromSso = url.split('#auth=');
      const tokenString = returnedFromSso[1];
      const tokenStringAdfs = tokenString.split('&exp')[0].replace('adfs+', 'adfs ');
      getJWT(tokenStringAdfs, getJWTFail);
    }
  }
};

const setDomainCookie = (name, val, expires) => {
  Cookies.set(name, val, { expires });
};

/* eslint-disable prefer-destructuring */
const getJWT = (token, getJWTFail) => {
  const instance = Axios.create();
  const apiUri = `${env('apiEndpoint')}/swap?rp=${env('metadataApiRpId')}`;

  instance.post(apiUri, token)
    .then(
      ({ data: { Credential: { Data, Expires }, Endpoint } }) => {
        const jwt = Data.Token;
        const expiry = new Date(new Date(Expires).toUTCString());
        setDomainCookie(authJWT, jwt, expiry);
        setDomainCookie(authJWTExpire, moment(expiry).format('YYYY-MM-DDTHH:mm:ss'), expiry);
        setItem('backEndpoint', Endpoint);
        window.location.href = window.location.href.split('#auth')[0];
      },
    )
    .catch(({ response: { status } }) => {
      if (status === 400) {
        if (getItem('needRedirect')) {
          removeItem('needRedirect');
          window.location.href = window.location.href.split('#auth')[0];
          return false;
        }

        getJWTFail(status);
      }
    });
};
/* eslint-enable prefer-destructuring */

const eraseCookie = (name) => {
  Cookies.remove(name);
};
