import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { MODAL_TYPE_WARNING } from '../../store/modules/modal';

export default class CreateNewConfigModal extends Component {
  state = {
    tenantToExtend: [],
    version: '',
    showModalForNewConfig: false,
  };

  componentDidUpdate() {
    const {
      configData,
      requestStatusCode,
      showModal,
      activeTenant,
      tenantsData,
      updateConfigSuccess,
      resolvedConfigData,
      updateRequestStatusCode,
      updateConfigRequest,
      validateConfigRequest,
    } = this.props;
    const { tenantToExtend, version, showModalForNewConfig } = this.state;

    if (requestStatusCode) {
      showModal(
        'Warning!',
        MODAL_TYPE_WARNING,
        'A configuration does not exist yet for that tenant - would you like to create one?',
        () => updateRequestStatusCode(0),
        'No',
        'Yes',
        () => {
          this.setState({ showModalForNewConfig: true });
          updateRequestStatusCode(0);
        },
      );
    }

    if (showModalForNewConfig) {
      const tenantsDataForSelect = tenantsData
        .filter(({ value }) => value !== activeTenant.value)
        .map(item => ({
          ...item,
          isDisabled: !activeTenant.extendConfigurations.includes(item.value),
        }));

      const bodyForCreateNewConfigModal = (
        <div className="create-new-config">
          <div className="create-new-config-item">
            <span className="item-label">Version: </span>
            <div className="item-content">
              <input className="input" value={version} onChange={this.onChangeVersion} />
            </div>
          </div>
          <div className="create-new-config-item">
            <span className="item-label">Tenants to extend: </span>
            <div className="item-content">
              <Select
                className="select"
                classNamePrefix="select-dropdown"
                value={tenantToExtend}
                onChange={option => this.onChangeTenantToExtend(option)}
                options={tenantsDataForSelect}
                isMulti
              />
            </div>
          </div>
        </div>
      );


      showModal(
        `Create a configuration for ${activeTenant.label}`,
        '',
        bodyForCreateNewConfigModal,
        () => this.setState({ showModalForNewConfig: false, version: '', tenantToExtend: [] }),
        'Cancel',
        'Save new config',
        () => {
          const extendsArray = tenantToExtend.map(({ value }) => ({
            configuration: value,
          }));
          const newConfig = !extendsArray.length ? { ...configData, version } : { ...configData, version, extends: extendsArray };
          const newConfigData = {
            config: newConfig,
            resolvedConfig: { ...resolvedConfigData, version },
          };
          updateConfigSuccess(newConfigData);
          this.setState({ showModalForNewConfig: false, version: '', tenantToExtend: [] });
          if (extendsArray.length) {
            updateConfigRequest(newConfig);
          } else {
            validateConfigRequest(newConfig);
          }
        },
      );
    }
  }

  onChangeTenantToExtend = tenant => {
    this.setState({ tenantToExtend: tenant });
  };

  onChangeVersion = ({ target: { value } }) => {
    this.setState({ version: value });
  };


  render() {
    return null;
  }
}

CreateNewConfigModal.propTypes = {
  tenantsData: PropTypes.array.isRequired,
  activeTenant: PropTypes.object.isRequired,
  configData: PropTypes.object.isRequired,
  resolvedConfigData: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  requestStatusCode: PropTypes.number.isRequired,
  updateConfigSuccess: PropTypes.func.isRequired,
  updateRequestStatusCode: PropTypes.func.isRequired,
  updateConfigRequest: PropTypes.func.isRequired,
  validateConfigRequest: PropTypes.func.isRequired,
};
