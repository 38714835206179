const prefix = 'REACT_APP_';
// eslint-disable-next-line import/no-mutable-exports
let env;

if (process.env.NODE_ENV === 'development') {
  env = key => process.env[`${prefix}${key}`];
} else {
  env = key => window[key] || '';
}

export default env;
