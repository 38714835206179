import React from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { onLogin } from './action-login';
import Logout from '../../components/Logout';
import { getIsSuccessJWTRequest } from '../../selectors';
import { getJWTFail } from '../../store/modules/config';

const Login = ({ children, getJWTFail: getJWTFailFunc, isSuccessJWTRequest }) => {
  const auth = Cookies.get('authJWT');
  let authExpire = Cookies.get('authJWTExpire');

  if (!isSuccessJWTRequest) {
    return <Logout />;
  }

  if (!auth || !authExpire) {
    onLogin(getJWTFailFunc);
    return null;
  }
  const now = moment();
  authExpire = moment(authExpire);

  if (now > authExpire) {
    onLogin(getJWTFailFunc);
    return null;
  }

  return children;
};


Login.propTypes = {
  children: PropTypes.node.isRequired,
  getJWTFail: PropTypes.func.isRequired,
  isSuccessJWTRequest: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isSuccessJWTRequest: getIsSuccessJWTRequest(state),
});

const mapDispatchToProps = { getJWTFail };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
