import React from 'react';
import PropTypes from 'prop-types';

import './GlobalLoader.css';
import Loader from '../Loader';

const GlobalLoader = ({ isLoaderVisible }) => (
  <div className={`global-loader ${isLoaderVisible ? 'show' : 'hide'}`}>
    <Loader />
  </div>
);

GlobalLoader.propTypes = {
  isLoaderVisible: PropTypes.bool.isRequired,
};

export default GlobalLoader;
