/* eslint-disable  react/destructuring-assignment, react/prop-types */

import './ErrorBoundary.css';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <h1 className="ErrorBoundary">Something went wrong try again later</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
