import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchConfigRequest, updateConfigSuccess, updateRequestStatusCode, updateConfigRequest, validateConfigRequest } from '../store/modules/config';
import { setActiveTenant } from '../store/modules/tenants';
import { showModal } from '../store/modules/modal';

import {
  getTenantsData,
  getActiveTenant,
  getServerConfig,
  getConfigData,
  getRequestStatus,
  getResolvedConfigData,
} from '../selectors';

import Header from '../components/Header';

const mapStateToProps = state => ({
  tenantsData: getTenantsData(state),
  activeTenant: getActiveTenant(state),
  configData: getConfigData(state),
  serverConfig: getServerConfig(state),
  resolvedConfigData: getResolvedConfigData(state),
  requestStatusCode: getRequestStatus(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchConfigRequest,
  setActiveTenant,
  showModal,
  updateConfigSuccess,
  updateRequestStatusCode,
  updateConfigRequest,
  validateConfigRequest,
}, dispatch);

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

export default HeaderContainer;
