import './Loader.css';

import React from 'react';

import LoaderGear from './Loader.gif';

const Loader = () => (
  <div className="Loader">
    <img src={LoaderGear} alt="Loader Gear" className="Loader__Gear" />
  </div>
);

export default Loader;
