import React from 'react';
import { Provider } from 'react-redux';

import configureStore, { sagaMiddleware } from '../../store/configureStore';

import rootSaga from '../../sagas';

import AppContainer from '../../containers/AppContainer';
import Login from '../../utils/auth/Login';

const store = configureStore();

sagaMiddleware.run(rootSaga);

const ConfigEditor = () => (
  <Provider store={store}>
    <Login>
      <AppContainer />
    </Login>
  </Provider>
);

export default ConfigEditor;
