import React, { Component } from 'react';
import { destroyLoginSession } from './action-login';
import env from '../../config';

class Logout extends Component {
  logout = (callback) => {
    destroyLoginSession();
    setTimeout(callback, 0);
  };

  redirect() {
    window.location.href = env('ssoRedirectUrl');
  }

  render() {
    return (
      <div className="App_Logout">
        <div className="row row-section">
          <div className="col-md-12">
            <button
              className="btn btn-default ButtonBar__Button App_Logout_btn"
              onClick={() => this.logout(this.redirect)}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Logout;
